<template>
  <div>
    <b-card
        title="发布流程"
        class="statement-card"
    >
      <el-steps
          :active="2"
          align-center
      >
        <el-step
            title="1.起草声明"
            icon="el-icon-edit"
        />
        <el-step
            title="2.声明预览"
            icon="el-icon-document"
        />
        <el-step
            title="3.声明审核"
            icon="el-icon-upload"
        />
        <el-step
            title="3.声明发布"
            icon="el-icon-upload"
        />
      </el-steps>
    </b-card>
    <b-card title="模版选项">
      <el-form
          ref="form2"
          :label-position="'top'"
          label-width="120px"
      >
        <b-row>
          <b-col
              v-for="(item,index) in templateOptionsList"
              :key="index"
              md="3">
            <el-form-item :label="item.optionsName">
              <el-radio-group v-model="item.isExist">
                <el-radio :label="true">
                  是
                </el-radio>
                <el-radio :label="false">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </b-col>
        </b-row>
      </el-form>
      <div class="dialog-footer text-center">
        <el-button
            size="small"
            @click="back">
          返 回
        </el-button>
        <el-button
            type="primary"
            size="small"
            @click="nextPage">
          下 一 步
        </el-button>
      </div>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { GetTemplateOptions } from '@/api/enterprise/statement'

export default {
  data() {
    return {
      templateOptionsList: [],
      dataForm: {
        templateCode: '',
      },
      queryParams: {
        templateCode: '',
        pageNo: 1,
        pageSize: 10,
        statementId: 0,
        productNames: '',
        title: '',
      },
    }
  },
  created() {
    this.getParams()
    this.getTemplateOptions()
  },
  methods: {
    back() {
      const backParam = {
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSize,
        statementId: this.queryParams.statementId,
        productNames: this.queryParams.productNames,
        title: this.queryParams.title,
      }
      this.$router.push({
        path: '/statement-version',
        query: backParam,
      })
    },
    getParams() {
      this.queryParams = this.$route.query
      if (this.queryParams.templateCode === undefined || this.queryParams.statementId === undefined) {
        this.$message.error('参数错误')
      }
      this.dataForm.templateCode = this.queryParams.templateCode
      if (this.queryParams.filterIdList) {
        this.templateOptionsList.filter(item => item.isExist === false).map(item => item.id)
      }
    },
    getTemplateOptions() {
      if (this.dataForm.templateCode === undefined) {
        this.$message.error('参数错误')
      } else {
        GetTemplateOptions(this.dataForm.templateCode).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.templateOptionsList = res.data.map(item => {
              const backItem = item
              if (this.queryParams.filterIdList && this.queryParams.filterIdList.includes(`${item.id}`)) {
                backItem.isExist = false
              }
              return backItem
            })
          }
        })
      }
    },
    nextPage() {
      const queryQ = this.queryParams
      queryQ.filterIdList = this.templateOptionsList.filter(item => item.isExist === false).map(item => item.id)
      this.$router.push({
        path: '/statement-version-edit',
        query: queryQ,
      })
    },
  },
}
</script>

<style lang="scss">
.statement-card{
  .el-step__title.is-finish, .el-step__head.is-finish {
    color: #0d40b2 !important;
    border-color: #0d40b2 !important;
  }
}
.statement-upload-btn{
  position: absolute;
  right: 20px;
  top: 10px;
}
</style>
